
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import Preloader from '@/views/components/Preloader.vue'
import { axiosApi } from '@/models/http/axiosApi'
import { Data, OfferClick } from '@/models/interfaces/QuotesList'
import OfferClickDto from '@/models/dto/OfferClickDto'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'QuotesList',
  data: (): Data => {
    return {
      loading: true,
      campaignResponse: [],
      titles: [
        'Top Auto Insurance in {city}!<br><span class="text-sm">TIP: Select multiple providers to find the best insurance rates.</span>',
        'You\'ll love these epic quotes in {city}!<br>Click on 2 or more insurers below to compare',
        '{firstName} Please Compare Multiple Providers<br>to Find the Best Rates!',
        'Congratulations, check out your options below!<br>{firstName}, click on the offers below to see your quotes!'
      ],
      defaultTitle: 'Please Compare Multiple Providers<br>to Find the Best Rates!',
      sentence: ''
    }
  },
  components: {
    Preloader
  },
  methods: {
    firstIndex (index: number) {
      return index === 0 ? 'first-item shadow-listItem' : 'offer-item shadow-cardShadow'
    },
    clickedOffer (offer: OfferClick) {
      offer.clientId = this.$cookie.getCookie('client_id')
      offer.campaignRequestId = this.$cookie.getCookie('campaign_request_id') ?? ''
      segmentTracking('Clicked On Quote', 0, { client_id: offer.clientId })
      axiosApi.post('/offer-click', new OfferClickDto(offer))
    },
    clickBackTOHomepage () {
      segmentTracking('Clicked On Back To Home Page')
    }
  },
  mounted () {
    const clientCookie = this.$cookie.getCookie('client_id')

    axiosApi.get(`/transparently/${clientCookie}`)
      .then(response => {
        this.campaignResponse = response.data
      }).finally(() => {
        this.loading = false
      })

    axiosApi.get(`/client/${clientCookie}`)
      .then(response => {
        this.sentence = response.data
          ? this.sentence = this.titles[Math.floor((Math.random() * this.titles.length))]
            .replace('{city}', response.data.city)
            .replace('{firstName}', response.data.firstName)
          : this.defaultTitle
      })
  },
  setup () {
    useMeta({ title: 'Quotes List ' })
  }
})
