import { OfferClick } from '@/models/interfaces/OfferClick'

export default class OfferClickDto {
  public readonly adCreativeId: number
  public readonly advertiserId: number
  public readonly adRequestId: string
  public readonly brandName: string
  public readonly clickUrl: string
  public readonly clientId: string
  public readonly isDataClickEnabled: boolean
  public readonly publisherRevenue: number
  public readonly campaignRequestId: string

  constructor (data: OfferClick) {
    this.adCreativeId = data.adCreativeId ? Number(data.adCreativeId) : 0
    this.advertiserId = data.advertiserId ? Number(data.advertiserId) : 0
    this.adRequestId = data.adRequestId ? String(data.adRequestId) : ''
    this.brandName = data.brandName ? String(data.brandName) : ''
    this.clickUrl = data.clickUrl ? String(data.clickUrl) : ''
    this.clientId = data.clientId ? String(data.clientId) : ''
    this.isDataClickEnabled = data.isDataClickEnabled ? Boolean(data.isDataClickEnabled) : false
    this.publisherRevenue = data.publisherRevenue ? Number(data.publisherRevenue) : 0
    this.campaignRequestId = data.campaignRequestId ? String(data.campaignRequestId) : ''
  }
}
